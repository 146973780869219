export const roomColors = [
    "#cf2e24",
    "#e69635",
    "#e79d7e",
    "#fbeb50",
    "#5da247",
    "#2b347e",
    "#5e297e",
    "#d95d98",
    "#cccccc",
    "#888888",
];
