export const drawingColors = [
    "#000000",
    "#8d0e00",
    "#e02d25",
    "#2A2A2A",
    "#ad7100",
    "#fc9a00",
    "#555555",
    "#d7d700",
    "#ffff01",
    "#AAAAAA",
    "#3fff9c",
    "#03ad54",
    "#D5D5D5",
    "#4fadff",
    "#0167ca",
    "#ffffff",
    "#fd93ff",
    "#970199",
];
